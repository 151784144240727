const Projects = {
  projects: [
    {
      id: 1,
      name: "FlowWeave",
      description:
        "A Flow based Visual coding App for JavaScript. Inspired from Unreal Engines’s blueprints",
      status: "Coming soon",
      tags: ["Personal Project"],
      image: "/Assets/FlowWeave/titleImage.gif",
    },
  ],
};

export function getData() {
  return Projects;
}
